import md5 from 'js-md5'
export var SearchList = [
  { labe: '采购订单', code: 'order', type: 'input', placeholder: '编码/名称' },
  { labe: '对账单编号', code: 'number', type: 'input' },
  { labe: '公司名称', code: 'corporateName', type: 'input', placeholder: '编码/名称' },
  { labe: '供应商', code: 'supplier', type: 'input', placeholder: '编码/名称' },
  {
    labe: '创建日期',
    code: 'createDate',
    type: 'daterange'
  },
  {
    labe: '对账单状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '待确认', value: '0' },
      { label: '已完成', value: '1' },
      { label: '审批中', value: '2' },
      { label: ' 驳回', value: '3' }
    ]
  },
  {
    labe: '对账单类型',
    code: 'statementType',
    type: 'select',
    option: [
      { label: '正常采购', value: '0' },
      { label: '分期付款', value: '1' }
    ]
  }
]
export var SearchData = {
  number: '',
  corporateName: '',
  supplier: '',
  // createDate: '',
  strStartDate: '',
  strEndDate: '',
  status: '',
  statementType: ''
}

export var tableField = [
  { label: '对账单编号', code: 'number', type: 'input', width: '125' },
  // { label: '公司名称', code: 'corporateName', type: 'input', width: '120' },
  {
    label: '公司名称',
    code: 'corporateName',
    type: 'function',
    width: '140px',
    handle (index, row) {
      return `${row.corporateCode}-${row.corporateName}`
    }
  },
  // { label: '供应商名称', code: 'supplier', type: 'input', width: '120' },
  {
    label: '供应商名称',
    code: 'supplier',
    type: 'function',
    width: '140px',
    handle (index, row) {
      return `${row.supplier}-${row.supplierCode}`
    }
  },
  {
    label: '开票方',
    code: 'billingParty',
    type: 'function',
    width: '140px',
    handle (index, row) {
      return `${row.billingParty}-${row.billingPartyCode}`
    }
  },
  // { label: '开票方', code: 'billingParty', type: 'input', width: '120' },
  { label: '含税总额', code: 'invoiceAmountIncludingTax', type: 'amount', align: 'right', width: '110' },
  { label: '币种', code: 'currency', type: 'input', width: '70' },
  {
    label: '对账单类型',
    code: 'statementType',
    type: 'function',
    width: '120',
    handle: (index, data) => {
      return data.statementType === 0 ? '正常采购' : '分期付款'
    }
  },
  {
    label: '对账单状态',
    code: 'status',
    type: 'function',
    width: '120',
    handle: (index, data) => {
      const color = md5(data.status + '').substring(0, 6)
      const label = data.status === 0 ? '待确认' : data.status === 1 ? '已完成'
        : data.status === 2 ? '审批中' : '驳回'
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '网上发票状态',
    code: 'createOnlineInvoice',
    type: 'function',
    width: '120',
    handle: (index, data) => {
      const color = md5(data.createOnlineInvoice + '').substring(0, 6)
      const label = data.createOnlineInvoice === 0 ? '完全创建' : data.createOnlineInvoice === 1 ? '未创建' : '部分创建'
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '是否寄售',
    code: 'consignmentOrNot',
    type: 'function',
    width: '110',
    handle: (index, data) => {
      const color = md5(data.consignmentOrNot + '').substring(0, 6)
      const label = data.consignmentOrNot === 0 ? '是' : '否'
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  { label: '备注', code: 'remarks', type: 'input', width: '' },
  { label: '创建人', code: 'createUserId', type: 'input', width: '80' },
  { label: '创建日期', code: 'createDate', type: 'input', width: '120' },
  {
    label: '操作',
    code: 'act',
    width: '',
    fixed: 'right',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData = []
export var menudata = [{
  label: '导出',
  action: 'draftContract',
  id: 1
}
]
