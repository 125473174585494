<template>
<!--采购对账管理单列表  -->
    <div class="list">
      <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
       <!-- <el-button @click="buttonClick " class="derivebtn" type="text" >导出</el-button> -->
      <div class="contractMenu">
        <list-menu @buttonClick="buttonClick" :menuData="menudata" ></list-menu>
      </div>
      <global-table :tableField="tableField"  :tableData="tableData" :paginationData="paginationData" @tableAction="tableAction" @pageChange="pageChange"></global-table>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { requestForm, exportForm } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import qs from 'qs'
import { MIME } from '@/assets/js/mime.js'
import { SearchList, SearchData, tableField, tableData, menudata } from './js/statement'
export default {
  components: Component.components,
  name: 'Statement',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      childData: { searchList: SearchList, searchData: SearchData },
      searchdata: SearchData,
      tableField: tableField,
      tableData: tableData,
      menudata: menudata,
      paginationData: {},
      pageNum: 1,
      pageSize: pageSize
    }
  },
  created () {
    this.statementList()
  },
  methods: {
    statementList () {
      var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
      if (pageSize < 10) {
        pageSize = 10
      }
      var obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      requestForm('/api/reconciliation/reconciliationInfo/queryAll', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.paginationData = { pageSize: pageSize, total: res.data.total }
        }
      })
    },
    tableAction: function (index, data, clickBtn) {
      this.$router.push({ params: { viewName: 'StatementSeeDetail' }, query: { number: data.number, id: data.id } })
    },
    buttonClick () {
      var pageNum = this.searchdata.pageNum
      if (pageNum === undefined) {
        pageNum = 1
      }
      this.searchdata.pageNum = pageNum
      this.searchdata.pageSize = this.pageSize
      console.log('pageNum', this.searchdata.pageNum)
      var file = { name: '对账单列表.xls' }
      exportForm('/api/reconciliation/reconciliationInfo/poiAccount?' + qs.stringify(this.searchdata), 'post').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      if (data.createDate) {
        this.searchdata.strEndDate = this.dateFormate(data.createDate[1])
        this.searchdata.strStartDate = this.dateFormate(data.createDate[0])
      }
      requestForm('/api/reconciliation/reconciliationInfo/queryAll', 'post', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    },
    pageChange (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      requestForm('/api/reconciliation/reconciliationInfo/queryAll', 'post', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
// @import '@/assets/css/tablePadding.scss';
.contractMenu{
  float: right;
}
</style>
